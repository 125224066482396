'use client';

import { Item } from '@/api';
import Container from '@/components/Layout/Container';
import ProductTile from '@/components/ecommerce/ProductTile/ProductTile';
import Pagination from '@/components/general/Pagination';
import { SearchResponse, searchQueryFn } from '@/queries/search';
import { DisplaySortOptions, DisplaySortOptionsType, SortOptions } from '@/types/API';
import { useMutation } from '@tanstack/react-query';
import clsx from 'clsx';
import { usePathname, useSearchParams } from 'next/navigation';
import { useEffect, useMemo, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import i18next from 'i18n';
import xor from 'lodash.xor';
import SearchFilters, { DefaultFilters } from '../ecommerce/search/SearchFilters';

export interface SearchProps {
    anchor?: string;
    defaultFilter?: DefaultFilters
    defaultSort?: SortOptions
    isDefaultFilterFixed?: boolean
    searchTitle?: string
    hideTitle?: boolean
    searchQuery?: string
    onDefaultFilterChanged?: () => void
}

const DEFAULT_LIMIT = 24;

const arrayEqual = (arr1?: unknown[], arr2?: unknown[]) => arr1 && arr2 && arr1.length === arr2.length && xor(arr1, arr2).length === 0;

export function Search(props: SearchProps) {
    const { anchor, searchTitle, searchQuery, defaultFilter, isDefaultFilterFixed = false, onDefaultFilterChanged, defaultSort, hideTitle = false } = props;
    const pathname = usePathname();
    const searchParams = useSearchParams();
    const searchTopRef = useRef<HTMLDivElement>(null);
    const [itemsPerPage, setItemsPerPage] = useState(DEFAULT_LIMIT);

    const [offset, setOffset] = useState<number>(0);

    const [data, setData] = useState<SearchResponse | null>(null);

    const [initDone, setInitDone] = useState<boolean>(false);

    const [artistsFilters, setArtistsFilters] = useState<string[]>([]);
    const [offerTypesFilters, setOfferTypesFilters] = useState<string[]>([]);
    const [conditionsFilters, setConditionsFilters] = useState<string[]>([]);
    const [genresFilters, setGenresFilters] = useState<string[]>([]);
    const [categoriesFilters, setCategoriesFilters] = useState<string[]>([]);
    const [subCategoriesFilters, setSubCategoriesFilters] = useState<string[]>([]);
    const [collectionsFilters, setCollectionsFilter] = useState<string[]>([]);

    const defaultSortMem = useMemo(() => {
        return (defaultSort ? DisplaySortOptions.find((o) => o.value === defaultSort) : undefined) || DisplaySortOptions[0];
    }, [defaultSort]);

    const [sortBy, setSortBy] = useState<DisplaySortOptionsType | null>(defaultSortMem);

    useEffect(() => {
        setOffset(0);
    }, [itemsPerPage]);

    const searchMutation = useMutation({
        mutationFn: searchQueryFn,
        onSuccess: (response) => {
            setData(response);
        },
        onError: async (error) => {
        }
    });

    const isMountingRef = useRef(false);
    const prevOffset = useRef(offset);

    useEffect(() => {
        if (isMountingRef.current === false) {
            isMountingRef.current = true;
        } else if (prevOffset.current !== offset) {
            prevOffset.current = offset;
            searchTopRef.current?.scrollIntoView({
                block: 'start'
            });
        }
    }, [offset]);

    useEffect(() => {
        const { subcategories, categories, artists, genres, conditions, collections, offerTypes } = (defaultFilter || {});
        setArtistsFilters(searchParams?.get('artists')?.split(',') || artists || []);
        setOfferTypesFilters(searchParams?.get('offerTypes')?.split(',') || offerTypes || []);
        setConditionsFilters(searchParams?.get('conditions')?.split(',') || conditions || []);
        setCategoriesFilters(searchParams?.get('categories')?.split(',') || categories || []);
        setSubCategoriesFilters(searchParams?.get('sub_categories')?.split(',') || subcategories || []);
        setGenresFilters(searchParams?.get('genres')?.split(',') || genres || []);
        setCollectionsFilter(searchParams?.get('collections')?.split(',') || collections || []);

        if (searchParams?.get('sort_by') && Object.values(SortOptions).includes(searchParams?.get('sort_by') as SortOptions)) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            setSortBy(DisplaySortOptions.find((o) => o.value === (searchParams?.get('sort_by') as SortOptions))!);
        }

        try {
            const userSettingPageSize = localStorage.getItem('page_size');
            if (userSettingPageSize) {
                setItemsPerPage(parseInt(userSettingPageSize as string, 10));
            }
            // eslint-disable-next-line no-empty
        } catch (error) {
        }
        if (searchParams?.get('offset') && !Number.isNaN(parseInt(searchParams?.get('offset') as string, 10))) {
            setOffset(parseInt(searchParams?.get('offset') as string, 10));
        }
        setInitDone(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!initDone) {
            return;
        }
        const current = new URLSearchParams(Array.from(searchParams?.entries()));

        const genresValue = genresFilters.join(',');
        if (!genresValue || (arrayEqual(genresFilters, defaultFilter?.genres))) {
            current.delete('genres');
        } else {
            current.set('genres', genresValue);
        }

        const artistValue = artistsFilters.join(',');
        if (!artistValue || (arrayEqual(artistsFilters, defaultFilter?.artists))) {
            current.delete('artists');
        } else {
            current.set('artists', artistValue);
        }

        const offerTypesValue = offerTypesFilters.join(',');
        if (!offerTypesValue || (arrayEqual(offerTypesFilters, defaultFilter?.offerTypes))) {
            current.delete('offerTypes');
        } else {
            current.set('offerTypes', offerTypesValue);
        }

        const conditionsValue = conditionsFilters.join(',');
        if (!conditionsValue || (arrayEqual(conditionsFilters, defaultFilter?.conditions))) {
            current.delete('conditions');
        } else {
            current.set('conditions', conditionsValue);
        }

        const categoriesValue = categoriesFilters.join(',');
        if (!categoriesValue || (arrayEqual(categoriesFilters, defaultFilter?.categories))) {
            current.delete('categories');
        } else {
            current.set('categories', categoriesValue);
        }

        const subCategoriesValue = subCategoriesFilters.join(',');
        if (!subCategoriesValue || (arrayEqual(subCategoriesFilters, defaultFilter?.subcategories))) {
            current.delete('sub_categories');
        } else {
            current.set('sub_categories', subCategoriesValue);
        }

        const collectionsValue = collectionsFilters.join(',');
        if (!collectionsValue || (arrayEqual(collectionsFilters, defaultFilter?.collections))) {
            current.delete('collections');
        } else {
            current.set('collections', collectionsValue);
        }

        if (!sortBy?.value || sortBy.value === defaultSortMem.value) {
            current.delete('sort_by');
        } else {
            current.set('sort_by', sortBy.value);
        }

        if (itemsPerPage !== DEFAULT_LIMIT) {
            try {
                localStorage.setItem('page_size', `${itemsPerPage}`);
                // eslint-disable-next-line no-empty
            } catch (error) {
            }
        }

        if (offset > 0) {
            current.set('offset', `${offset}`);
        } else {
            current.delete('offset');
        }

        const search = current.toString();
        window.history.replaceState({}, '', `${pathname}${search ? `?${search}` : ''}`);
        searchMutation.mutate({
            query: searchQuery || '',
            limit: itemsPerPage,
            offset,
            artists: artistsFilters,
            offerTypes: offerTypesFilters,
            genres: genresFilters,
            conditions: conditionsFilters,
            categories: categoriesFilters,
            subCategories: subCategoriesFilters,
            collections: collectionsFilters,
            sortBy: sortBy?.value
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchQuery, categoriesFilters, categoriesFilters, artistsFilters, genresFilters, collectionsFilters, conditionsFilters, subCategoriesFilters, offset, sortBy, itemsPerPage, offerTypesFilters, defaultSortMem]);

    return (

        <Container className="!px-0 lg:px-0 max-w-[1280px] mx-auto bg-white">
            {!hideTitle && (
                <div className="pt-[60px] pb-[40px] md:pt-[80px] md:pb-[60px] px-[20px] md:px-[40px] bg-cover bg-no-repeat bg-center" style={{ backgroundImage: "url('/assets/blue_texture.jpeg')" }}>
                    <h1 className="font-heading capitalize text-xl md:text-[58px] text-white z-10">{searchTitle} {data ? `(${data?.pagination?.total})` : ''}</h1>
                </div>
            )}
            <div id={anchor} ref={searchTopRef} className={clsx('flex md:flex-row flex-col justify-between md:px-[40px]', { 'pr-[0px] md:pr-[60px]': !hideTitle })}>
                <div className="w-full">
                    <div className="my-3 border-b-[1px] px-[20px] md:px-0">
                        <SearchFilters
                            defaultFilters={defaultFilter}
                            isDefaultFilterFixed={isDefaultFilterFixed}
                            meta_available={data?.meta_available}
                            artistsFilters={artistsFilters}
                            conditionsFilters={conditionsFilters}
                            genresFilters={genresFilters}
                            collectionsFilters={collectionsFilters}
                            categoriesFilters={categoriesFilters}
                            subCategoriesFilters={subCategoriesFilters}
                            offerTypeFilters={offerTypesFilters}
                            sortBy={sortBy}
                            setOfferTypeFilters={(filter) => {
                                if (defaultFilter?.offerTypes && filter.length < 1 && onDefaultFilterChanged) {
                                    onDefaultFilterChanged();
                                } else {
                                    setOffset(0);
                                    setOfferTypesFilters(filter);
                                }
                            }}
                            setArtistsFilters={(filter) => {
                                if (defaultFilter?.artists && filter.length < 1 && onDefaultFilterChanged) {
                                    onDefaultFilterChanged();
                                } else {
                                    setOffset(0);
                                    setArtistsFilters(filter);
                                }
                            }}
                            setCollectionsFilters={(filter) => {
                                if (defaultFilter?.collections && filter.length < 1 && onDefaultFilterChanged) {
                                    onDefaultFilterChanged();
                                } else {
                                    setOffset(0);
                                    setCollectionsFilter(filter);
                                }
                            }}
                            setConditionsFilters={(filter) => {
                                setOffset(0);
                                if (defaultFilter?.conditions && filter.length < 1 && onDefaultFilterChanged) {
                                    onDefaultFilterChanged();
                                } else {
                                    setOffset(0);
                                    setConditionsFilters(filter);
                                }
                            }}
                            setCategoriesFilters={(filter) => {
                                if (defaultFilter?.categories && filter.length < 1 && onDefaultFilterChanged) {
                                    onDefaultFilterChanged();
                                } else {
                                    setOffset(0);
                                    setCategoriesFilters(filter);
                                }
                            }}
                            setGenresFilters={(filter) => {
                                if (defaultFilter?.genres && filter.length < 1 && onDefaultFilterChanged) {
                                    onDefaultFilterChanged();
                                } else {
                                    setOffset(0);
                                    setGenresFilters(filter);
                                }
                            }}
                            setSubCategoriesFilters={(filter) => {
                                if (defaultFilter?.subcategories && filter.length < 1 && onDefaultFilterChanged) {
                                    onDefaultFilterChanged();
                                } else {
                                    setOffset(0);
                                    setSubCategoriesFilters(filter);
                                }
                            }}
                            setSortBy={setSortBy}
                            loading={data === null && searchMutation.isLoading}
                        />
                    </div>
                    {searchMutation.isLoading || data === null ?
                        <div className="">
                            <div className="px-[20px] md:px-0 mb-[24px] grid md:grid-cols-[1fr_1fr_1fr_1fr] tablet:grid-cols-[1fr_1fr_1fr] sm-tablet:grid-cols-[1fr_1fr] grid-cols-[1fr] gap-x-8">
                                <div className="md:my-[13px] mb-[24px]">
                                    <Skeleton height={350} count={1} />
                                </div>
                                <div className="md:my-[13px] mb-[24px]">
                                    <Skeleton height={350} count={1} />
                                </div>
                                <div className="md:my-[13px] mb-[24px]">
                                    <Skeleton height={350} count={1} />
                                </div>
                                <div className="md:my-[13px] mb-[24px]">
                                    <Skeleton height={350} count={1} />
                                </div>
                            </div>
                        </div> :
                        <div className={clsx('min-h-[500px] mb-[24px] px-[20px] md:px-0', data && data.results && data?.results?.length > 0 && 'grid md:grid-cols-[1fr_1fr_1fr_1fr] tablet:grid-cols-[1fr_1fr_1fr] sm-tablet:grid-cols-[1fr_1fr] grid-cols-[1fr] gap-x-8')}>
                            {data && data.results && data?.results?.length > 0 ?
                                data.results.map((searchResult: Item) => (
                                    <div key={searchResult.id} className="md:my-[13px] border-b-[1px] md:border-none border-[#ccc] mb-[40px] md:mb-[0px] pb-[16px] md:pb-[0px]">
                                        <ProductTile fullWidth data={searchResult} />
                                    </div>
                                )) :
                                <div className=" flex flex-row justify-center w-full py-[140px] text-center">
                                    <div className="w-[271px] text-center">
                                        <h3 className="font-heading font-[400] capitalize text-[40px]">{i18next.t('search.no-results')}</h3>
                                    </div>
                                </div>
                            }
                        </div>
                    }

                    <Pagination
                        factor={4}
                        itemsPerPageChanged={setItemsPerPage}
                        defaultItemsPerPage={itemsPerPage}
                        data={data?.pagination}
                        offsetChanged={setOffset} />

                </div>
            </div>
        </Container>
    );
}
